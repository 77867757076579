import React from 'react';
import { connect } from 'react-redux';
import { changeLocatie, changeRuimte } from '../../redux/actions'

import './Agenda.scss';
// import Dateselect from './Dateselect';
import Form from './Form';

class Agenda extends React.Component {

    changeLocatie = event => {
        this.props.dispatch(changeLocatie(event.target.value));
        this.props.dispatch(changeRuimte(0));
    }

    changeRuimte = event => {
        this.props.dispatch(changeRuimte(event.target.value));
    }
    componentDidMount = () => {
        if(this.props.location.state) {
            this.props.dispatch(changeLocatie(this.props.location.state.locatie));
            this.props.dispatch(changeRuimte(this.props.location.state.ruimte));
        }

    }

    render() {
        var data = this.props.data;
        return (
            <div>
                <div className="agenda-select">
                    <div className="agenda-select-locatie">
                        <select value={this.props.locatie} onChange={this.changeLocatie}>
                            <option value={data.vinkenhof.naam}>{data.vinkenhof.locatie}</option>
                            <option value={data.ojk.naam}>{data.ojk.locatie}</option>
                            <option value={data.buurtkerk.naam}>{data.buurtkerk.locatie}</option>
                            <option value={data.windkrachthuis.naam}>{data.windkrachthuis.locatie}</option>
                        </select>
                        <img src={this.props.imghost + data[this.props.locatie].afbeeldingen[0]} alt=""/>
                    </div>
                    <div className="agenda-select-zaal">
                        <select value={this.props.ruimte} onChange={this.changeRuimte}>
                            {data[this.props.locatie].ruimten.map((ruimte, index) => <option key={index} value={index}>{ruimte.naam}</option>)}
                        </select>
                        <img src={this.props.imghost + data[this.props.locatie].ruimten[this.props.ruimte].afbeeldingen[0]} alt=""/>
                    </div>
                </div>
                {/* <Dateselect /> */}
                <div className="agenda-bar1">
                    <h4>Reservering aanvragen? via onderstaande links, of bellen met 071 3616402</h4>
                </div>
                <div className="agenda-bar2">
                    <h4>Aan deze aanvraag kunnen geen rechten worden ontleend, fouten in prijzen en teksten zijn voorbehouden</h4>
                </div>
                <Form data={data} locatie={this.props.locatie} ruimte={this.props.ruimte} imghost={this.props.imghost}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.data,
        imghost: state.imghost,
        locatie: state.locatie,
        ruimte: state.ruimte
    }
}

export default connect(mapStateToProps)(Agenda);