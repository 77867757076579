import React from 'react';

import Locatie from './Locatie';

const Locaties = () => {
    return (
        <div>
            <Locatie locatie="vinkenhof"/>
            <Locatie locatie="ojk"/>
            <Locatie locatie="buurtkerk"/>
            <Locatie locatie="windkrachthuis"/>
        </div>
    );
}

export default Locaties;