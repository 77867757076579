import React from "react";

import "./Info.scss";

const Info = () => (
    <div className="info">
        <div>
            <h3>Beheer gebouwen</h3>
            <p>
                Ivonne Clemens<br />
                071 3616402<br />
                <a href="mailto:devinkenhof@pkn-noordwijk.nl">devinkenhof@pkn-noordwijk.nl</a>
            </p>
        </div>
        <div>
            <h3>Adressen</h3>
            <p>
                De Vinkenhof<br />
                Achterzeeweg 1, 2201 EM Noordwijk
            </p>
            <p>
                Oude Jeroenskerk<br />
                Voorstraat 44, 2201 HW Noordwijk
            </p>
            <p>
                Buurtkerk<br />
                Hoofdstraat 10, 2202 GD Noordwijk
            </p>
            <p>
                Windkrachthuis<br />
                Hoofdstraat 8, 2202 GD Noordwijk
            </p>
        </div>
    </div>
);

export default Info;