export const CHANGE_DATA = "CHANGE_DATA";
export const CHANGE_IMGHOST = "CHANGE_IMGHOST";
export const CHANGE_LOCATIE = "CHANGE_LOCATIE";
export const CHANGE_RUIMTE = "CHANGE_RUIMTE";

export const changeData = data => ({
    type: CHANGE_DATA,
    payload: data
});

export const changeImghost = imghost => ({
    type: CHANGE_IMGHOST,
    payload: imghost
});

export const changeLocatie = locatie => ({
    type: CHANGE_LOCATIE,
    payload: locatie
});
export const changeRuimte = ruimte => ({
    type: CHANGE_RUIMTE,
    payload: ruimte
});