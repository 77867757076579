import { CHANGE_DATA, CHANGE_IMGHOST, CHANGE_LOCATIE, CHANGE_RUIMTE } from "./actions.js";

export const data = (state = {}, action) => {
    switch (action.type) {
        case CHANGE_DATA:
            return action.payload;
        default:
            return state;
    }
};

export const imghost = (state = "", action) => {
    switch (action.type) {
        case CHANGE_IMGHOST:
            return action.payload;
        default:
            return state;
    }
};

export const locatie = (state = "vinkenhof", action) => {
    switch (action.type) {
        case CHANGE_LOCATIE:
            return action.payload;
        default:
            return state;
    }
};

export const ruimte = (state = 0, action) => {
    switch (action.type) {
        case CHANGE_RUIMTE:
            return action.payload;
        default:
            return state;
    }
};
