import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import toAgenda from "../../img/toAgenda.svg"

import "./Ruimten.scss";
import Slider from './Slider';

const Ruimten = ({ match, data }) => {
    var locatie = data[match.params.locatie];
    return (
        <div>
            <h2>Ruimten</h2>
            {locatie.ruimten.map((ruimte, index) => (
                <div className="ruimten" key={ruimte.naam + ruimte.punten}>
                    <div className="ruimten-header">
                        <div className="ruimten-header-section">
                            <h3>{ruimte.naam}</h3>
                            <p>{ruimte.punten[0]}</p>
                            <p>{ruimte.punten[1]}</p>
                            <p>{ruimte.punten[2]}</p>
                        </div>
                        <Slider afbeeldingen={ruimte.afbeeldingen}/>
                        <div className="ruimten-header-link">
                            <Link to={{
                                pathname: '/agenda',
                                state: {
                                    locatie: locatie.naam,
                                    ruimte: index
                                }
                            }}>
                            <img src={toAgenda} alt=""/>
                            </Link>
                        </div>
                        
                    </div>
                    <p>{ruimte.beschrijving}</p>
                </div>
            ))}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        data: state.data
    }
}

export default connect(mapStateToProps)(Ruimten);