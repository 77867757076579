import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import "./Zalen.scss";

import Ruimten from './Ruimten';

const Zalen = ({ data, imghost }) => {
    return (
        <div>
            <h2>Locatie</h2>
            <nav>
                <ul>
                    <li><NavLink activeClassName="selected" to="/zalen/vinkenhof"><img src={imghost + data.vinkenhof.afbeeldingen[0]} alt="" /><h3>Vinkenhof</h3></NavLink></li>
                    <li><NavLink activeClassName="selected" to="/zalen/ojk"><img src={imghost + data.ojk.afbeeldingen[0]} alt="" /><h3>Oude Jeroenskerk</h3></NavLink></li>
                    <li><NavLink activeClassName="selected" to="/zalen/buurtkerk"><img src={imghost + data.buurtkerk.afbeeldingen[0]} alt="" /><h3>Buurtkerk</h3></NavLink></li>
                    <li><NavLink activeClassName="selected" to="/zalen/windkrachthuis"><img src={imghost + data.windkrachthuis.afbeeldingen[0]} alt="" /><h3>Windkrachthuis</h3></NavLink></li>
                </ul>
            </nav>
            <Route path="/zalen/:locatie" component={Ruimten}/>

            <div className="footer">
                <h4>Reservering aanvragen? via de agenda, of bellen met 071 3616402</h4>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        data: state.data,
        imghost: state.imghost
    }
}

export default connect(mapStateToProps)(Zalen);