import React from 'react';
import { BrowserRouter as Router, Route, NavLink, Switch, Link } from 'react-router-dom';

import Locaties from './pages/Locaties/Locaties';
import Zalen from './pages/Zalen/Zalen';
import Agenda from './pages/Agenda/Agenda';
import Info from './pages/Info/Info';
import NietGevonden from './pages/NietGevonden/NietGevonden';

import './App.scss'

import logo from './img/logo.svg';

const App = () => {
    return (
        <main>
            <Router>
                <header>
                    <Link to="/"><img src={ logo } alt="" /></Link>
                    <h1>Locatiesnoordwijk.nl</h1>
                    <ul>
                        <li><NavLink exact activeClassName="pagina" to="/">Locaties</NavLink></li>
                        <li><NavLink activeClassName="pagina" to="/zalen">Zalen</NavLink></li>
                        <li><NavLink activeClassName="pagina" to="/agenda">Agenda</NavLink></li>
                        {/* <li><NavLink activeClassName="pagina" to="/fotos">Foto's</NavLink></li> */}
                        <li><NavLink activeClassName="pagina" to="/info">Info</NavLink></li>
                    </ul>
                </header>
                <Switch>
                    <Route exact path="/" component={Locaties}/>
                    <Route path="/zalen" component={Zalen}/>
                    <Route path="/agenda" component={Agenda}/>
                    <Route path="/info" component={Info}/>
                    <Route component={NietGevonden}/>
                </Switch>
            </Router>
        </main>
    );
}

export default App;
