import { combineReducers, createStore } from "redux";
import { data, imghost, locatie, ruimte } from "./reducers";

const persistedState = {
    data: require('../data.json'),
    imghost: "//" + window.location.host + "/img/"
};

export const store = createStore(
    combineReducers({
        data,
        imghost,
        locatie,
        ruimte
    }),
    persistedState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
