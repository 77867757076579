import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import './Form.scss';

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            naam: "",
            email: "",
            tel: "",
            omschrijving: "",
            mailSent: false,
            error: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // Next 3 functions to stop lastpass from clogging up the console
    componentDidMount () {
        document.addEventListener('keydown', this.handleHitEnter, true)
    }
    
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleHitEnter, true)
    }
    
    handleHitEnter(e) {
        const ENTER_KEY_CODE = 13
        if (e.target.name === 'omschrijving' &&
            (e.key === 'Enter' || e.keyCode === ENTER_KEY_CODE)) {
            e.stopPropagation();
        }
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit(event) {
        event.preventDefault();

        var postdata = this.state;
        postdata["locatie"] = this.props.data[this.props.locatie].locatie;
        postdata["ruimte"] = this.props.data[this.props.locatie].ruimten[this.props.ruimte].naam;
        axios({
            method: 'post',
            url: window.location.protocol + '//' + window.location.host + '/api.php',
            // url: window.location.protocol + '//' + window.location.hostname + ':8000/api.php',
            headers: { 'content-type': 'application/json' },
            data: postdata
        })
        .then(result => {
            this.setState({
                mailSent: result.data.sent
            })
        })
        .catch(error => {
            this.setState({
                error: error.message
            })
        })
        
    }

    validatePhone(phone) {
        var vast_nummer = /^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/;
        var mobiel_nummer = /^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;
        return (vast_nummer.test(phone) || mobiel_nummer.test(phone));
    }

    render() {
        return (
            <div className="form">
                <div className="form-header">
                    <h3>Selectie Aanvraag:</h3>
                    <p>{this.props.data[this.props.locatie].locatie}: {this.props.data[this.props.locatie].ruimten[this.props.ruimte].naam}</p>
                </div>

                <form className="form-form" onSubmit={this.handleSubmit}>
                    <label className="form-form-label">
                        Naam:
                        <input
                            type="text"
                            name="naam"
                            placeholder="Uw naam of Bedrijf"
                            value={this.state.naam}
                            onChange={this.handleInputChange}
                            />
                    </label>
                    <label className="form-form-label">
                        E-mail:
                        <input
                            type="email"
                            name="email"
                            placeholder="Uw e-mailadres"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            />
                    </label>
                    <label className="form-form-label">
                        Tel:
                        <input
                            type="tel"
                            name="tel"
                            placeholder="Uw telefoonnummer"
                            value={this.state.tel}
                            onChange={this.handleInputChange}
                            />
                    </label>
                    <label className="form-form-label">
                        Omschrijving:
                        <textarea
                            name="omschrijving"
                            placeholder="Uw Bericht, beschrijf uw activiteiten, welke data u in gedachten heeft e.d."
                            cols="50"
                            rows="10"
                            value={this.state.omschrijving}
                            onChange={this.handleInputChange}
                            />
                    </label>
                    
                    <input
                        type="submit"
                        value="Verzenden"
                        />
                </form>

                <div>
                    {this.state.mailSent && 
                        <div>Verzonden!</div>
                    }
                    {this.state.error && 
                        <div>{this.state.error}</div>
                    }
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.data,
        imghost: state.imghost,
        locatie: state.locatie,
        ruimte: state.ruimte
    }
}

export default connect(mapStateToProps)(Form);