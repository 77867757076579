import React from 'react';

import "./NietGevonden.scss";

const NietGevonden = ({ location }) => {
    return (
        <div className="error">
            <div>
                <h3>Niet gevonden</h3>
                <p>
                    De pagina "{location.pathname}" kon niet worden gevonden.
                </p>
            </div>
            
        </div>
    );
};

export default NietGevonden;