import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import "./Locatie.scss";

class Locatie extends React.Component {
    constructor(state) {
        super(state);
        this.state = {
            featuredImg: 0,
            leesMeer: false
        }
    }

    clickHandler = img => {
        this.setState({
            featuredImg: img
        });
    }

    leesMeerHandler = () => {
        this.setState({
            leesMeer: true
        });
    }

    render() {
        var locatie = this.props.data[this.props.locatie];
        var imghost = this.props.imghost;
        return (
            <div className="locatie">
                <figure className="locatie-figure">
                    <img className="locatie-figure-img" src={imghost + locatie.afbeeldingen[this.state.featuredImg]} alt="" />
                    <div>
                        {locatie.punten.map(punt => (
                            <p key={punt}>{punt}</p>
                        ))}
                    </div>
                </figure>
                <div className="locatie-header">
                    <h3 className="locatie-header-titel">{locatie.locatie} <span>{locatie.ruimten.length} {locatie.ruimten.length === 1 ? "zaal" : "zalen"}</span></h3>
                    <figure className="locatie-header-selector">
                        <img onClick={() => {this.clickHandler(0)}} src={imghost + locatie.afbeeldingen[0]} alt="" />
                        <img onClick={() => {this.clickHandler(1)}} src={imghost + locatie.afbeeldingen[1]} alt="" />
                        <img onClick={() => {this.clickHandler(2)}} src={imghost + locatie.afbeeldingen[2]} alt="" />
                    </figure>
                </div>
                <p>{locatie.beschrijving}</p>
                { this.state.leesMeer ?
                    <div>
                        <p>{locatie.beschrijving_lang}</p>
                        <div className="locatie-buttonwrapper">
                            <Link to={"zalen/" + locatie.naam}>Bekijk zalen</Link>
                        </div>
                    </div>
                :
                    <p className="locatie-leesmeer"onClick={this.leesMeerHandler}>Lees meer...</p>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.data,
        imghost: state.imghost
    }
}

export default connect(mapStateToProps)(Locatie);