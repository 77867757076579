import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import "./Slider.scss";

class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            foto: this.props.afbeeldingen[0]
        }
    }

    clickDot(foto) {
        this.setState({
            foto: foto
        });
    }

    render() {
        return (
            <figure className="slider">
                <img className="slider-img" src={this.props.imghost + this.state.foto} alt="" />
                <div className="slider-dots">
                {this.props.afbeeldingen[1] ? this.props.afbeeldingen.map((foto, index) => (
                    <span key={index} style={this.state.foto === foto ? {backgroundColor: "#ccc"} : {}} onClick={() => {this.clickDot(foto)}}></span>
                )) : ""}
                </div>
            </figure>
        );
    }
}

const mapStateToProps = state => {
    return {
        imghost: state.imghost
    }
}

export default connect(mapStateToProps)(Slider);